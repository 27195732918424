import { faHandHoldingUsd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../Button';
import { menuDrawerItemProps } from '../MenuDrawerItems';

const UnserAngebot = () => (
  <Button
    link={{ href: '/offer' }}
    title="Unser Angebot"
    variant="menu"
    leftIcon={<FontAwesomeIcon icon={faHandHoldingUsd} />}
    {...menuDrawerItemProps}
  />
);

export default React.memo(UnserAngebot);
