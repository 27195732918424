import styled from 'styled-components';

const Ul = styled('ul')`
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;

  > li {
    list-style: none;
    position: absolute;
    left: 5%;
    width: 90%;
    height: 3px;
    transition: 0.5s;
    background: #000000;
    transform: translateY(-50%) rotate(0);
    opacity: 1;
    border-radius: 5px;

    &:nth-child(1) {
      top: 20%;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 80%;
    }
  }
`;

export default Ul;
