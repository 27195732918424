import type { FC } from 'react';

import React, { createRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Card from '../Card';
import DesktopContainer from '../DesktopContainer';
import MobileContainer from '../MobileContainer';

interface MenuItemProps {
  TopComponent: React.FC<{ open?: boolean }>;
  children: React.ReactNode;
}

const Wrapper = styled('div')`
  position: relative;
  z-index: 1000;
`;

const TopComponentWrapper = styled('div')``;

const ChildrenWrapper = styled(Card)<{ open: boolean }>`
  position: absolute;
  display: ${({ open }) => (open ? 'block' : 'none')};
  background-color: ${(props) => props.theme.colors.white};

  & > * {
    white-space: nowrap;
  }
`;

const MenuItem: FC<MenuItemProps> = ({ TopComponent, children }) => {
  const [open, setOpen] = useState(false);
  const handleClickTopComponent = useCallback(
    () => setOpen((prevState) => !prevState),
    []
  );

  const rootRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!rootRef?.current) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (!rootRef?.current) return;
      if (!rootRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [rootRef]);

  return (
    <Wrapper ref={rootRef}>
      <TopComponentWrapper onClick={handleClickTopComponent}>
        <TopComponent open={open} />
      </TopComponentWrapper>
      <DesktopContainer>
        <ChildrenWrapper open={open}>{children}</ChildrenWrapper>
      </DesktopContainer>
      <MobileContainer>{children}</MobileContainer>
    </Wrapper>
  );
};

export default React.memo(MenuItem);
