import Link from 'next/link';
import React, { useContext, useMemo } from 'react';

import Page from '../../blocks/Page';
import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import Container from '../Container';
import HamburgerMenu from '../HamburgerMenu';
import ProgressBar from '../ProgressBar';
import { PTypography } from '../Typography';

import MenuDrawerItems from './MenuDrawerItems';

import useCurrentUser from '$/hooks/useCurrentUser';
import { HeaderContext } from '$/providers/HeaderProvider';
import { MobileNavigationContext } from '$/providers/MobileNavigationProvider';

const allowedStatuses = [14, 15, 16, 17, 112];

const Header = ({ shiftLeft }: { shiftLeft: boolean }) => {
  const { progressBarValue, headerButton } = useContext(HeaderContext);
  const { toggleMobileMenuOpen } = useContext(MobileNavigationContext);
  const { authenticated, userData } = useCurrentUser();

  const includeContainer = useMemo(() => {
    if (typeof userData?.status === 'number') {
      return allowedStatuses.includes(userData?.status);
    }
    return false;
  }, [userData?.status]);

  if (typeof progressBarValue === 'number') {
    return (
      <Page.Header style={{ boxShadow: 'none' }} shiftLeft={shiftLeft}>
        {shiftLeft && <div />}
        <Container>
          <Page.Header.StyledNav>
            <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
            <ProgressBar value={progressBarValue} />
          </Page.Header.StyledNav>
        </Container>
      </Page.Header>
    );
  }

  if (typeof headerButton !== 'undefined') {
    return (
      <Page.Header shiftLeft={shiftLeft}>
        {shiftLeft && <div />}
        <Container>
          <Page.Header.StyledNav>
            <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
            <ButtonContainer columns="1fr auto">
              <div />
              <Button
                variant="text"
                title={headerButton.text}
                buttonProps={headerButton.props}
              />
            </ButtonContainer>
          </Page.Header.StyledNav>
        </Container>
      </Page.Header>
    );
  }

  if (authenticated) {
    return (
      <Page.Header shiftLeft={shiftLeft}>
        {shiftLeft && <div />}
        {includeContainer ? (
          <Container>
            <Page.Header.StyledNav>
              {userData?.status === 115 ? (
                <Link href="/" passHref>
                  <a>
                    <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
                  </a>
                </Link>
              ) : (
                <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
              )}
              <Page.Header.StyledHamburgerWrapper
                onClick={toggleMobileMenuOpen}
              >
                <span style={{ margin: '0 9px' }}>
                  <PTypography>Menu</PTypography>
                </span>
                <HamburgerMenu />
              </Page.Header.StyledHamburgerWrapper>

              <Page.Header.StyledDesktopNav>
                <MenuDrawerItems />
              </Page.Header.StyledDesktopNav>
            </Page.Header.StyledNav>
          </Container>
        ) : (
          <Page.Header.StyledNav>
            {userData?.status === 115 ? (
              <Link href="/" passHref>
                <a>
                  <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
                </a>
              </Link>
            ) : (
              <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
            )}
            <Page.Header.StyledHamburgerWrapper onClick={toggleMobileMenuOpen}>
              <span style={{ margin: '0 9px' }}>
                <PTypography>Menu</PTypography>
              </span>
              <HamburgerMenu />
            </Page.Header.StyledHamburgerWrapper>

            <Page.Header.StyledDesktopNav>
              <MenuDrawerItems />
            </Page.Header.StyledDesktopNav>
          </Page.Header.StyledNav>
        )}
      </Page.Header>
    );
  }

  return (
    <Page.Header style={{ boxShadow: 'none' }} shiftLeft={shiftLeft}>
      {shiftLeft && <div />}
      <Container>
        <Page.Header.StyledNav>
          <Page.Header.StyledLogo src="/images/logo.svg" alt="logo" />
        </Page.Header.StyledNav>
      </Container>
    </Page.Header>
  );
};

export default Header;
