import styled from 'styled-components';

import theme from '$/assets/theme';

const StyledText = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  letter-spacing: 0.1px;
  color: ${theme.colors.grey7};
`;

export default StyledText;
