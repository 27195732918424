import styled from 'styled-components';

const StyledDesktopNav = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 32px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export default StyledDesktopNav;
