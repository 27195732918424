import styled from 'styled-components';

const StyledIconContainer = styled('div')`
  display: flex;
  justify-content: center;

  svg {
    font-size: 16px;
  }
`;

export default StyledIconContainer;
