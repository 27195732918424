import styled from 'styled-components';

import theme from '$/assets/theme';

const StyledBar = styled.div<any>`
  height: 4px;
  width: ${(props) =>
    props.value > 100
      ? 100
      : props.value >= 0 && props.value <= 100
      ? props.value
      : 0}%;
  border-radius: 2px;
  transition: width 0.3s;
  background: ${(props) =>
    props.value >= 100 ? theme.colors.brandGreen : theme.colors.grey7};
`;

export default StyledBar;
