import styled from 'styled-components';

const Wrapper = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 430px 1fr;
  align-items: center;
  padding-bottom: 110px;

  min-height: 160px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

export default Wrapper;
