import styled from 'styled-components';

const StyledHamburgerWrapper = styled.div`
  display: none;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: flex;
  }
`;

export default StyledHamburgerWrapper;
