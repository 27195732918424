import Link from 'next/link';
import React, { useContext } from 'react';

import { CurrentUserContext } from '../../../providers/CurrentUserProvider';
import Page from '../../blocks/Page';
import Container from '../Container';
import { PTypography } from '../Typography';

const Footer = (props: any) => {
  const { authenticated, userQuery } = useContext(CurrentUserContext);
  return (
    <Page.Footer
      as="footer"
      hide={authenticated && userQuery?.data?.getUserData?.status === 14}
      {...props}
    >
      <Container>
        <Page.Footer.Content>
          {userQuery?.data?.getUserData?.id && (
            <PTypography>
              Kunden-ID {userQuery?.data?.getUserData?.id}
            </PTypography>
          )}
          <PTypography color="grey5">
            <Link href="https://www.deutsche-bildung.de/impressum" passHref>
              <a target="_blank" rel="noopener noreferrer">
                <PTypography
                  as="span"
                  color="grey5"
                  style={{ display: 'inline' }}
                >
                  Impressum
                </PTypography>
              </a>
            </Link>
            <span> | </span>
            <Link href="https://www.deutsche-bildung.de/datenschutz" passHref>
              <a target="_blank" rel="noopener noreferrer">
                <PTypography
                  as="span"
                  color="grey5"
                  style={{ display: 'inline' }}
                >
                  Datenschutz
                </PTypography>
              </a>
            </Link>
          </PTypography>
        </Page.Footer.Content>
      </Container>
    </Page.Footer>
  );
};

export default Footer;
