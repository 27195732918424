import styled from 'styled-components';

import StyledImage from './StyledImage';
import Title from './Title';
import Underline from './Underline';
import Wrapper from './Wrapper';

const Component = styled('section')<{ background?: string }>`
  width: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  margin-bottom: 32px;
  transition: all 1000ms;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 0;
    margin-bottom: 100px;
  }

  &:not(:first-child) {
    background: ${(props) => props.background ?? props.theme.colors.white};
  }
`;

type ComponentProps = typeof Component & {
  StyledImage: typeof StyledImage;
  Title: typeof Title;
  Underline: typeof Underline;
  Wrapper: typeof Wrapper;
};

const HeadContentSection = Component as ComponentProps;

HeadContentSection.StyledImage = StyledImage;
HeadContentSection.Title = Title;
HeadContentSection.Underline = Underline;
HeadContentSection.Wrapper = Wrapper;

export default HeadContentSection;
