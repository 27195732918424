import React from 'react';
import styled, { css } from 'styled-components';

import StyledChildrenWrapper from './StyledChildrenWrapper';
import StyledContainer from './StyledContainer';
import StyledDesktopNav from './StyledDesktopNav';
import StyledHamburgerWrapper from './StyledHamburgerWrapper';
import StyledLogo from './StyledLogo';
import StyledNav from './StyledNav';

const shiftLeft = css`
  display: grid;
  grid-template-columns: minmax(300px, 410px) 1fr;
`;

const Header: any = styled('header')<any>`
  grid-area: nav;
  padding: 4px 20px;
  height: calc(70px - 8px);
  box-shadow: ${(props) =>
    props.boxShadow &&
    '0 1px 1px 0 rgba(10, 22, 70, 0.15), 0 0 1px 0 rgba(10, 22, 70, 0.1)'};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    background: ${(props) => props.theme.colors.white};
    box-shadow:
      0 1px 1px 0 rgba(10, 22, 70, 0.15),
      0 0 1px 0 rgba(10, 22, 70, 0.1);
    margin-bottom: 1px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 8px 32px;
    height: calc(70px - 16px);
    ${(props) => props.shiftLeft && shiftLeft};
  }
`;

Header.StyledChildrenWrapper = StyledChildrenWrapper;
Header.StyledContainer = StyledContainer;
Header.StyledHamburgerWrapper = StyledHamburgerWrapper;
Header.StyledLogo = StyledLogo;
Header.StyledNav = StyledNav;
Header.StyledDesktopNav = StyledDesktopNav;

export default Header;
