import styled from 'styled-components';

const Content = styled('div')<{ justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 80px 0 0;
`;

export default Content;
