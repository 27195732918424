import styled from 'styled-components';

const Card = styled('div')`
  padding: 4px;
  box-shadow:
    0 1px 4px 0 rgba(10, 22, 70, 0.15),
    0 0 1px 0 rgba(10, 22, 70, 0.1);
`;

export default Card;
