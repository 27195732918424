import styled from 'styled-components';

import theme from '$/assets/theme';

const DrawerItem = styled.li`
  margin: 8px 0;
  cursor: pointer;
  transition: 0.25s;

  &:last-child {
    margin-top: 16px;
  }

  &:hover {
    background: ${theme.colors.brandBlue10};
    color: ${theme.colors.brandBlue};
    border-radius: 4px;
  }
`;

export default DrawerItem;
