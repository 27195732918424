import styled from 'styled-components';

const CloseButton = styled('div')`
  display: flex;
  justify-content: flex-end;

  > div {
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export default CloseButton;
