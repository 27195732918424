import styled from 'styled-components';

import theme from '$/assets/theme';

const Content = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr;
  background: ${theme.colors.brandBlue10};
  border-radius: unset;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 11;

  @media (min-width: ${theme.breakpoints.lg}) {
    top: 10%;
    right: calc((100% - 800px) / 2);
    left: calc((100% - 800px) / 2);
    bottom: 20%;
    margin: 0 auto;
    width: 800px;
    border-radius: 10px;
  }
`;

export default Content;
