import styled, { css } from 'styled-components';

import HeadContentSection from '../HeadContentSection';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const withSidebar = css`
  grid-template-columns: minmax(300px, 410px) 1fr;
  grid-template-areas:
    'nav nav'
    'sidebar content';
  grid-template-rows: auto 1fr;
`;

const noSidebar = css`
  grid-template-columns: 1fr;
  grid-template-areas:
    'nav'
    'content'
    'footer';
  grid-template-rows: auto 1fr auto;
`;

const Page: any = styled('div')<{ background: string; sidebar: boolean }>`
  display: grid;
  min-height: 100vh;
  width: 100%;
  transition: all 1000ms;
  background: ${(props) => props.background || props.theme.colors.solitude};

  ${HeadContentSection} {
    background: ${(props) => props.background || props.theme.colors.solitude};
  }

  ${(props) => (props.sidebar ? withSidebar : noSidebar)};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'nav'
      'content'
      'footer';
    grid-template-rows: auto 1fr auto;
  }
`;

Page.Content = Content;
Page.Sidebar = Sidebar;
Page.Footer = Footer;
Page.Header = Header;

export default Page;
