import styled from 'styled-components';

import ProgressItem from './ProgressItem';
import ProgressList from './ProgressList';

const StepsBlock: any = styled.div``;

StepsBlock.ProgressList = ProgressList;
StepsBlock.ProgressItem = ProgressItem;

export default StepsBlock;
