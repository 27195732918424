import styled from 'styled-components';

const Underline = styled('span')`
  position: relative;
  width: fit-content;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom: 10px solid ${(props) => props.theme.colors.brandBlue50};
    z-index: -1;
  }
`;

export default Underline;
