import styled from 'styled-components';

import pkg from '$/../package.json';
import theme from '$/assets/theme';

const Container = styled('div')`
  padding: 0 20px;
  font-size: 13px;
  color: ${theme.colors.grey5};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const AppVersion = () => {
  return (
    <Container>
      <span>App-Version: v{pkg.version}</span>
    </Container>
  );
};

export default AppVersion;
