import type { ConsultantData } from '../../../API';

import {
  faEnvelopeSquare,
  faPhoneSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { useMemo } from 'react';

import Underline from '../../blocks/HeadContentSection/Underline';
import { H5Typography } from '../Typography';

import GridContainer from './GridContainer';
import StyledIconContainer from './StyledIconContainer';
import StyledText from './StyledText';

import useCurrentUser from '$/hooks/useCurrentUser';

type TConsultantData = Required<Omit<ConsultantData, '__typename' | 'id'>> & {
  phoneLink?: string;
};

export type TContactPanelProps = TConsultantData & {
  contactTitle: React.ReactNode;
  noMargin: boolean;
};

export const DEFAULT_CONSULTANT_DATA: TConsultantData = {
  imageUrl: '/images/helpDesk.jpg',
  firstName: 'Dein',
  lastName: 'Beratungsteam',
  phone: '+49 (0)69 – 92 03 94 5 – 12',
  phoneLink: 'tel:069920394512',
  email: 'bewerbung@deutsche-bildung.de',
};

const stripPhone = (phone: string): string => {
  return phone.replace(' ', '').replace('(', '').replace(')', '');
};

const ContactPanel = (props: Partial<TContactPanelProps>) => {
  const { userData } = useCurrentUser();

  const consultantData = useMemo((): TConsultantData => {
    const newData = {
      ...DEFAULT_CONSULTANT_DATA,
      ...(userData?.consultant ?? {}),
      ...(props ?? {}),
    };
    return {
      imageUrl: newData?.imageUrl?.length
        ? newData.imageUrl
        : DEFAULT_CONSULTANT_DATA.imageUrl,
      phone: newData?.phone?.length
        ? newData.phone
        : DEFAULT_CONSULTANT_DATA.phone,
      firstName: newData?.firstName?.length
        ? newData.firstName
        : DEFAULT_CONSULTANT_DATA.firstName,
      lastName: newData?.lastName?.length
        ? newData.lastName
        : DEFAULT_CONSULTANT_DATA.lastName,
      email: newData?.email?.length
        ? newData.email
        : DEFAULT_CONSULTANT_DATA.email,
    };
  }, [props]);

  return (
    <div style={props.noMargin ? { margin: 0 } : { margin: '40px 0' }}>
      <H5Typography as="h2" bold color="grey7" style={{ marginBottom: 19 }}>
        {props.contactTitle ?? (
          <>
            Du hast <Underline>Fragen?</Underline>
          </>
        )}
      </H5Typography>
      <GridContainer>
        <StyledIconContainer>
          <FontAwesomeIcon icon={faPhoneSquare} />
        </StyledIconContainer>
        <Link href={`tel:${stripPhone(consultantData.phone!)}`} passHref>
          <a target="_blank">
            <StyledText>{consultantData.phone}</StyledText>
          </a>
        </Link>
        <StyledIconContainer>
          <FontAwesomeIcon icon={faEnvelopeSquare} />
        </StyledIconContainer>
        <Link href={`mailto:${consultantData.email}`} passHref>
          <a target="_blank">
            <StyledText>{consultantData.email}</StyledText>
          </a>
        </Link>
      </GridContainer>
    </div>
  );
};

ContactPanel.defaultProps = {
  noMargin: false,
};

export default ContactPanel;
