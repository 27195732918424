import styled from 'styled-components';

import theme from '$/assets/theme';

const Background = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${theme.colors.grey6};
  opacity: 0.8;
  z-index: 10;
`;

export default Background;
