import styled from 'styled-components';

const Container = styled('div')`
  width: 33.125rem;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: calc(100% - 32px);
  }
`;

export default Container;
