import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import { useContext, useMemo } from 'react';

import { CurrentUserContext } from '../../../providers/CurrentUserProvider';
import { MobileNavigationContext } from '../../../providers/MobileNavigationProvider';
import MobileDrawer from '../../blocks/MobileDrawer';
import { PTypography } from '../Typography';

import MenuDrawerItems from './MenuDrawerItems';

import theme from '$/assets/theme';

const MobileMenuDrawer = () => {
  const { mobileMenuOpen, toggleMobileMenuOpen } = useContext(
    MobileNavigationContext
  );

  const { userQuery } = useContext(CurrentUserContext);

  const userData = userQuery.data?.getUserData;

  const fullName = useMemo(
    () => `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`,
    [userData?.firstName, userData?.lastName]
  );

  return (
    <Drawer
      open={mobileMenuOpen}
      onHandleClick={toggleMobileMenuOpen}
      onClose={toggleMobileMenuOpen}
      handler={false}
      placement="bottom"
      level="root"
      width="100%"
      height="100%"
    >
      <MobileDrawer.Container>
        <MobileDrawer.Wrapper>
          <MobileDrawer>
            <MobileDrawer.DrawerHeader>
              <PTypography as="h1" color="grey7" align="center">
                {fullName}
              </PTypography>

              <FontAwesomeIcon
                icon={faTimes}
                onClick={toggleMobileMenuOpen}
                cursor="pointer"
                style={{ fontSize: '24px' }}
                color={theme.colors.grey5}
              />
            </MobileDrawer.DrawerHeader>
            <MobileDrawer.DrawerList>
              <MenuDrawerItems />
            </MobileDrawer.DrawerList>
          </MobileDrawer>
        </MobileDrawer.Wrapper>
      </MobileDrawer.Container>
    </Drawer>
  );
};

export default MobileMenuDrawer;
