import React from 'react';

import Ul from './Ul';

const HamburgerMenu = () => (
  <Ul>
    <li />
    <li />
    <li />
  </Ul>
);

export default HamburgerMenu;
