import React from 'react';

import StyledBar from './StyledBar';
import StyledBarContainer from './StyledBarContainer';
import StyledContainer from './StyledContainer';
import StyledText from './StyledText';
import StyledWrapper from './StyledWrapper';

interface IProgressBarProps {
  value: number;
}

const ProgressBar = (props: IProgressBarProps) => (
  <StyledWrapper>
    <StyledText>
      {props.value > 100
        ? 100
        : props.value >= 0 && props.value <= 100
        ? props.value
        : 0}
      % fertig
    </StyledText>
    <StyledContainer>
      <StyledBarContainer>
        <StyledBar {...props} />
      </StyledBarContainer>
    </StyledContainer>
  </StyledWrapper>
);

const defaultProps = {
  value: 0,
};

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
