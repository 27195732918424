import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';

import Background from './Background';
import Content from './Content';

import CloseButton from '$/components/elements/Modal/CloseButton';
import { ModalContext } from '$/providers/ModalProvider';

interface IModalProps {
  show: boolean;
}

const Modal: React.FC<IModalProps> = (props) => {
  const modalContext = useContext(ModalContext);

  const handleCloseModal = () => {
    modalContext.handleShowContent(null);
  };

  if (!props.show) {
    return null;
  }

  return (
    <div>
      <Background onClick={handleCloseModal} />
      <Content>
        <CloseButton onClick={handleCloseModal}>
          <div>
            <FontAwesomeIcon icon={faXmark} size={'lg'} />
          </div>
        </CloseButton>
        {modalContext.content}
      </Content>
    </div>
  );
};

export default Modal;
