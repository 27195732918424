import React from 'react';
import styled from 'styled-components';

import MenuItem from './MenuItem';
import Abmelden from './MenuItems/Abmelden';
import Academy from './MenuItems/Academy/Academy';
import Bewerbungsberatung from './MenuItems/Academy/Bewerbungsberatung';
import Coaching from './MenuItems/Academy/Coaching';
import Community from './MenuItems/Academy/Community';
import ELearning from './MenuItems/Academy/ELearning';
import Mediathek from './MenuItems/Academy/Mediathek';
import Veranstaltungen from './MenuItems/Academy/Veranstaltungen';
import Anderes from './MenuItems/Anderes';
import AppVersion from './MenuItems/AppVersion';
import DeineAnfrage from './MenuItems/DeineAnfrage';
import DeineDaten from './MenuItems/DeinKonto/DeineDaten';
import DeinKonto from './MenuItems/DeinKonto/DeinKonto';
import TimeBufferRequestMenuItem from './MenuItems/DeinKonto/TimeBufferRequest';
import UpdateMitteilen from './MenuItems/DeinKonto/UpdateMitteilen';
import HilfeUndKontakt from './MenuItems/HilfeUndKontakt';
import Start from './MenuItems/Start';
import UnserAngebot from './MenuItems/UnserAngebot';

import useCurrentUser from '$/hooks/useCurrentUser';

export const menuDrawerItemProps = {
  iconContainerProps: { style: { marginRight: '16px' } },
  textProps: { style: { fontSize: '16px', fontWeigh: 'bold' } },
  buttonProps: {
    style: {
      justifyContent: 'flex-start',
      padding: '14px 10px',
      width: 'auto',
    },
  },
};

const MenuSection = styled('div')`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: flex;
    flex-direction: row;
  }
`;

const userStatusToMenuMap = new Map<number, React.FC>();

userStatusToMenuMap.set(14, () => {
  return (
    <>
      <MenuSection>
        <DeineAnfrage />
        <Anderes />
      </MenuSection>
      <MenuSection>
        <HilfeUndKontakt />
        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});
userStatusToMenuMap.set(15, () => {
  return (
    <>
      <MenuSection>
        <HilfeUndKontakt />
      </MenuSection>
      <MenuSection>
        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});
userStatusToMenuMap.set(16, () => {
  return (
    <>
      <MenuSection>
        <HilfeUndKontakt />
      </MenuSection>
      <MenuSection>
        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});
userStatusToMenuMap.set(17, () => {
  return (
    <>
      <MenuSection>
        <HilfeUndKontakt />
      </MenuSection>
      <MenuSection>
        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});

userStatusToMenuMap.set(19, () => {
  return (
    <>
      <MenuSection>
        <UnserAngebot />
        <Anderes />
      </MenuSection>
      <MenuSection>
        <HilfeUndKontakt />

        <Abmelden />
      </MenuSection>
    </>
  );
});

userStatusToMenuMap.set(20, () => {
  return (
    <>
      <MenuSection>
        <UnserAngebot />
        <Anderes />
      </MenuSection>
      <MenuSection>
        <HilfeUndKontakt />

        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});

userStatusToMenuMap.set(112, () => {
  return (
    <>
      <MenuSection>
        <HilfeUndKontakt />
      </MenuSection>
      <MenuSection>
        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});

userStatusToMenuMap.set(115, () => {
  return (
    <>
      <MenuSection>
        <Start />
        <MenuItem TopComponent={Academy}>
          <ELearning />
          <Bewerbungsberatung />
          <Veranstaltungen />
          <Coaching />
          <Community />
          <Mediathek />
        </MenuItem>
        <MenuItem TopComponent={DeinKonto}>
          <UpdateMitteilen />
          <TimeBufferRequestMenuItem />
          <DeineDaten />
        </MenuItem>
        <Anderes />
      </MenuSection>
      <MenuSection>
        <HilfeUndKontakt />

        <Abmelden />
        <AppVersion />
      </MenuSection>
    </>
  );
});

const MenuDrawerItems = () => {
  const { userData } = useCurrentUser();

  let RenderComponent: React.FC<any> | undefined | null;

  if (typeof userData?.status === 'number') {
    RenderComponent = userStatusToMenuMap.get(userData?.status);
  }

  return RenderComponent ? React.createElement(RenderComponent) : null;
};

export default MenuDrawerItems;
