import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const DeineDaten = () => (
  <Button
    title="Deine Daten"
    variant="menu"
    leftIcon={<FontAwesomeIcon icon={faUserCircle} />}
    {...menuDrawerItemProps}
    link={{ href: '/settings' }}
  />
);

export default React.memo(DeineDaten);
