import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';
import {
  ActiveNotificationContainer,
  default as ActiveNotification,
} from '../ActiveNotification';

const TimeBufferRequestMenuItem = () => {
  const { enableTimeBuffer } = useFlags();

  return enableTimeBuffer ? (
    <Button
      title="Zeitpuffer beantragen"
      variant="menu"
      leftIcon={
        <ActiveNotificationContainer>
          <FontAwesomeIcon icon={faFileAlt} />
          <ActiveNotification style={{ top: '-1px' }} />
        </ActiveNotificationContainer>
      }
      {...menuDrawerItemProps}
      link={{
        href: '/time-buffer',
      }}
    />
  ) : (
    <></>
  );
};

export default React.memo(TimeBufferRequestMenuItem);
