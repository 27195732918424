import { faRunning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const Coaching = () => {
  return (
    <Button
      title="Coaching"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faRunning} />}
      {...menuDrawerItemProps}
      link={{
        href: '/customer-portal/coaching',
      }}
    />
  );
};

export default React.memo(Coaching);
