import React from 'react';

import MobileContainer from '../../MobileContainer';
import { PTypography } from '../../Typography';
import { menuDrawerItemProps } from '../MenuDrawerItems';

const Anderes = () => (
  <MobileContainer>
    <PTypography
      style={{ ...menuDrawerItemProps.buttonProps.style, paddingBottom: '0px' }}
    >
      ANDERES
    </PTypography>
  </MobileContainer>
);

export default React.memo(Anderes);
