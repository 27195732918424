import type { TColor } from '$/assets/theme/colors';
import type { TFonts } from '$/assets/theme/fonts';
import type React from 'react';

import styled, { css } from 'styled-components';

export interface ITypography {
  color?: TColor;
  align?: 'left' | 'center' | 'right';
  fontFamily?: TFonts;
  bold?: boolean;
  children?: string | React.ReactNode;
  as?: string;
  style?: object;
  href?: string;
}

const sharedProps = css<ITypography>`
  display: block;
  font-family: ${(props) => props.theme.fonts[props.fontFamily]};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: ${(props) => props.align || 'left'};
  color: ${(props) => props.theme.colors[props.color]};
`;

const H1Typography = styled.h1<ITypography>`
  font-size: 60px;
  letter-spacing: 0.46px;
  ${sharedProps};
`;

const H2Typography = styled.h2<ITypography>`
  font-size: 42px;
  letter-spacing: 0.32px;
  ${sharedProps};
`;

const H3Typography = styled.h3<ITypography>`
  font-size: 32px;
  letter-spacing: 0.25px;
  ${sharedProps};
`;

const H4Typography = styled.h4<ITypography>`
  font-size: 28px;
  letter-spacing: 0.22px;
  ${sharedProps};
`;

const H5Typography = styled.h5<ITypography>`
  font-size: 24px;
  letter-spacing: 0.18px;
  ${sharedProps};
`;

const H6Typography = styled.h6<ITypography>`
  font-size: 18px;
  letter-spacing: 0.14px;
  ${sharedProps};
`;

const Sub1Typography = styled.span<ITypography>`
  font-size: 16px;
  letter-spacing: 0.12px;
  ${sharedProps};
`;

const Sub2Typography = styled.span<ITypography>`
  font-size: 15px;
  letter-spacing: 0.12px;
  ${sharedProps};
`;

const PTypography = styled.p<ITypography>`
  font-size: 13px;
  letter-spacing: 0.1px;
  ${sharedProps};
`;

const defaultProps: ITypography = {
  color: 'black',
  align: 'left',
  fontFamily: 'rawline',
  bold: false,
};

H1Typography.defaultProps = defaultProps;
H2Typography.defaultProps = defaultProps;
H3Typography.defaultProps = defaultProps;
H4Typography.defaultProps = defaultProps;
H5Typography.defaultProps = defaultProps;
H6Typography.defaultProps = defaultProps;
Sub1Typography.defaultProps = defaultProps;
Sub2Typography.defaultProps = defaultProps;
PTypography.defaultProps = defaultProps;

export {
  H1Typography,
  H2Typography,
  H3Typography,
  H4Typography,
  H5Typography,
  H6Typography,
  Sub1Typography,
  Sub2Typography,
  PTypography,
};
