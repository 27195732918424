import styled from 'styled-components';

const Image = styled('img')<{ mobileBigger?: boolean | string; top?: string }>`
  position: absolute;
  max-width: 240px;
  max-height: 200px;
  left: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: unset;
    height: ${(props) =>
      typeof props.mobileBigger === 'boolean'
        ? props.mobileBigger
          ? '150px'
          : '120px'
        : props.mobileBigger};
    left: unset;
    top: ${(props) => props.top || '45px'};
  }
`;

const Container = styled('div')`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = (props: any) => (
  <Container>
    <Image {...props} />
  </Container>
);

export default StyledImage;
