import styled, { css } from 'styled-components';

import Content from './Content';

const hide = css`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const Footer: any = styled('div')<{ hide?: boolean }>`
  grid-area: footer;
  padding: 0 32px 50px;
  background: ${(props) => props.theme.colors.grey0};

  ${(props) => props.hide && hide};

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    background: ${(props) => props.theme.colors.white};
  }
`;

Footer.Content = Content;

export default Footer;
