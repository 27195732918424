import React, { useMemo } from 'react';
import { useMedia } from 'react-use';

import Button from '../../Button';
import { menuDrawerItemProps } from '../MenuDrawerItems';

import theme from '$/assets/theme';

const HilfeUndKontakt = () => {
  const isMobile = useMedia(`(min-width: ${theme.breakpoints.lg}`);

  const desktopProps = useMemo(
    () => (isMobile ? { style: { fontWeight: 'normal' } } : null),
    [isMobile]
  );

  return (
    <Button
      title="Hilfe & Kontakt"
      variant="menu"
      {...menuDrawerItemProps}
      textProps={desktopProps}
      link={{
        href: '/customer-portal/help',
      }}
    />
  );
};

export default React.memo(HilfeUndKontakt);
