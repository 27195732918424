import styled from 'styled-components';

import theme from '$/assets/theme';

const StyledContainer = styled.div`
  height: 4px;
  width: 220px;
  max-width: 80%;
  background: ${theme.colors.grey2};
  border-radius: 2px;
  margin-top: 4px;
`;

export default StyledContainer;
