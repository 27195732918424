import React, { createContext, useState } from 'react';

import Modal from '$/components/elements/Modal';

interface IModalContext {
  handleShowContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  content: JSX.Element | null;
}

const defaultValue: IModalContext = {
  handleShowContent: () => undefined,
  content: null,
};

export const ModalContext = createContext<IModalContext>(defaultValue);

const setHTMLOverflow = (show: boolean) => {
  const html = document.getElementsByTagName('html');
  if (html) {
    const item = html.item(0);
    if (item) {
      item.style.overflowY = show ? 'hidden' : 'scroll';
    }
  }
};

const ModalProvider: React.FC = (props) => {
  const [content, setContent] = useState<JSX.Element | null>(null);

  const handleShowContent = (data: JSX.Element | null) => {
    setHTMLOverflow(!!data);
    setContent(data);
  };

  return (
    <ModalContext.Provider
      value={{
        handleShowContent,
        content,
      }}
    >
      {props.children}
      <Modal show={!!content}>{content}</Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
