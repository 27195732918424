import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 8px);
  height: calc(100% - 20px);
  border-radius: 10px;
  background: white;
  overflow: auto;
`;

export default Wrapper;
