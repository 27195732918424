import Link from 'next/link';
import React, { useContext } from 'react';

import { CurrentUserContext } from '../../../providers/CurrentUserProvider';
import Footer from '../../blocks/Footer';
import Page from '../../blocks/Page';
import ContactPanel from '../ContactPanel';
import Steps from '../Steps';
import { H6Typography, PTypography } from '../Typography';

const Sidebar = ({ display }: { display: boolean }) => {
  const { userQuery } = useContext(CurrentUserContext);
  const consultant = userQuery.data?.getUserData?.consultant;

  return (
    <Page.Sidebar hide={!display}>
      <Steps headerComponent={H6Typography} />
      <ContactPanel />
      <Footer.Content justifyContent="center">
        <PTypography color="grey5">
          <Link href="https://www.deutsche-bildung.de/impressum" passHref>
            <a target="_blank" rel="noopener noreferrer">
              <PTypography
                as="span"
                color="grey5"
                style={{ display: 'inline' }}
              >
                Impressum
              </PTypography>
            </a>
          </Link>
          <span> | </span>
          <Link href="https://www.deutsche-bildung.de/datenschutz" passHref>
            <a target="_blank" rel="noopener noreferrer">
              <PTypography
                as="span"
                color="grey5"
                style={{ display: 'inline' }}
              >
                Datenschutz
              </PTypography>
            </a>
          </Link>
        </PTypography>
      </Footer.Content>
    </Page.Sidebar>
  );
};

export default Sidebar;
