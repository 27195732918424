import styled from 'styled-components';

const Content = styled('main')`
  display: grid;
  position: relative;
  grid-area: content;
  z-index: 1;
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: 24px;
  }
`;

export default Content;
