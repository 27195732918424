import type { SetStateAction } from 'react';

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useState } from 'react';

export type TProgressItem = {
  id: string;
  number: string | React.ReactNode;
  text: string;
  completed: boolean;
  inactive: boolean;
  link?: string;
};

type TContext = {
  progressItems: TProgressItem[];
  setProgressItems: React.Dispatch<SetStateAction<TProgressItem[]>>;
};

export const StepsContext = createContext<TContext>({
  progressItems: [],
  setProgressItems: () => undefined,
});

const StepsProvider = (props) => {
  const [progressItems, setProgressItems] = useState<TProgressItem[]>([
    {
      id: '0',
      number: '1',
      text: 'Vorraussetzungen erfüllt',
      completed: true,
      inactive: true,
    },
    {
      id: '1',
      number: '2',
      text: 'Account erstellt',
      completed: true,
      inactive: true,
    },
    {
      id: '2',
      number: '3',
      text: 'Deine Wunschfinanzierung',
      completed: false,
      inactive: false,
      link: '/details/financing',
    },
    {
      id: '3',
      number: '4',
      text: 'Persönliche Informationen',
      completed: false,
      inactive: true,
      link: '/details/personal-infos',
    },
    {
      id: '4',
      number: '5',
      text: 'Dokumente hochladen',
      completed: false,
      inactive: true,
      link: '/details/document-upload',
    },
    {
      id: '5',
      number: <FontAwesomeIcon icon={faArrowRight} />,
      text: 'Dein individuelles Angebot',
      completed: false,
      inactive: true,
    },
  ]);

  return (
    <StepsContext.Provider value={{ progressItems, setProgressItems }}>
      {props.children}
    </StepsContext.Provider>
  );
};

export default StepsProvider;
