import styled from 'styled-components';

import theme from '$/assets/theme';

const Sidebar = styled('div')<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? 'none' : 'grid')};
  grid-template-rows: auto auto 1fr;
  grid-area: sidebar;
  padding: 32px;
  background: ${theme.colors.grey0};
  margin-top: -4px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export default Sidebar;
