import type { ReactChild } from 'react';

import Head from 'next/head';
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

import Page from '$/components/blocks/Page';
import { CurrentUserContext } from '$/providers/CurrentUserProvider';
import { ThemeBackgroundContext } from '$/providers/ThemeBackgroundProvider';

export interface LayoutProps {
  children: ReactChild;
  title?: string;
  background?: string;
  showFooter?: boolean;
}

const Layout = ({
  title,
  children,
  showFooter = false,
  ...props
}: LayoutProps) => {
  const { background } = useContext(ThemeBackgroundContext);
  const { authenticated, userQuery } = useContext(CurrentUserContext);

  title =
    title ?? userQuery?.data?.getUserData?.status === 115
      ? 'Deutsche Bildung Studienfinanzierung | Service Portal'
      : 'Deutsche Bildung Studienfinanzierung | Registrierung';

  const displaySidebar =
    authenticated && userQuery?.data?.getUserData?.status === 14;

  return (
    <>
      <Head>
        <title>{title}</title>
        <link
          rel="preload"
          href="/fonts/Rawline/RawlineRegular.ttf"
          as="font"
          type="font"
        />
      </Head>
      <Page
        sidebar={displaySidebar}
        background={props.background ?? background}
        {...props}
      >
        <Header shiftLeft={displaySidebar} />
        <Sidebar display={displaySidebar} />
        <Page.Content {...props} children={children} />
        {showFooter &&
          !(authenticated && userQuery?.data?.getUserData?.status === 14) && (
            <Footer />
          )}
        <ToastContainer
          closeOnClick={true}
          hideProgressBar={true}
          position="top-right"
        />
      </Page>
    </>
  );
};

export default Layout;
