import styled from 'styled-components';

import theme from '$/assets/theme';

const ProgressItem = styled.li`
  border-top: 1px solid ${theme.colors.grey2};

  &:first-child {
    border: none;
  }
`;

export default ProgressItem;
