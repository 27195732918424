import styled from 'styled-components';

const DrawerHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.grey0};
`;

export default DrawerHeader;
