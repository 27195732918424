import { faChalkboardUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import DeutscheBildungPageUrl from '$/../@types/deutscheBildungPageUrl';
import Button from '$/components/elements/Button';
import { menuDrawerItemProps } from '$/components/elements/Layout/MenuDrawerItems';

const ELearning = () => {
  return (
    <Button
      title="eLearning Angebote"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faChalkboardUser} />}
      {...menuDrawerItemProps}
      link={{
        href: DeutscheBildungPageUrl.CUSTOMER_PORTAL_ELEARNING,
      }}
    />
  );
};

export default React.memo(ELearning);
