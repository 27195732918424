import { faBoxBallot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const Bewerbungsberatung = () => {
  return (
    <Button
      title={'Bewerbungsberatung'}
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faBoxBallot} />}
      {...menuDrawerItemProps}
      link={{
        href: '/customer-portal/application-advice',
      }}
    />
  );
};

export default React.memo(Bewerbungsberatung);
