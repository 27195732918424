import styled from 'styled-components';

import { H4Typography } from '../../elements/Typography';

const Title = styled(H4Typography)<{ display?: string }>`
  display: ${(props) => props.display || 'flex'};
  flex-direction: column;
`;

export default Title;
