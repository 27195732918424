import styled from 'styled-components';

const GridContainer = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 5px;
  align-items: center;
`;

export default GridContainer;
