import type { IHeaderProviderProps } from '$/providers/HeaderProvider';

import React from 'react';

import HeaderProvider from '$/providers/HeaderProvider';
import MobileNavigationProvider from '$/providers/MobileNavigationProvider';
import ModalProvider from '$/providers/ModalProvider';
import StepsProvider from '$/providers/StepsProvider';
import ThemeBackgroundProvider from '$/providers/ThemeBackgroundProvider';

interface IProvidersProps extends IHeaderProviderProps {
  children: React.ReactNode;
}

const Providers = ({ children, ...props }: IProvidersProps) => (
  <ThemeBackgroundProvider>
    <ModalProvider>
      <HeaderProvider {...props}>
        <StepsProvider {...props}>
          <MobileNavigationProvider {...props}>
            {children}
          </MobileNavigationProvider>
        </StepsProvider>
      </HeaderProvider>
    </ModalProvider>
  </ThemeBackgroundProvider>
);

export default Providers;
