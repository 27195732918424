import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const Community = () => {
  return (
    <Button
      title="Netzwerk"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faUsers} />}
      {...menuDrawerItemProps}
      link={{
        href: '/customer-portal/community',
      }}
    />
  );
};

export default React.memo(Community);
