import { faPhotoVideo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const Mediathek = () => {
  return (
    <Button
      title="Mediathek"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faPhotoVideo} />}
      {...menuDrawerItemProps}
      link={{
        href: '/customer-portal/media-library',
      }}
    />
  );
};

export default React.memo(Mediathek);
