import styled from 'styled-components';

/**
 * Wrapper for `Button` element
 */

const ButtonContainer = styled('div')<{
  columns?: string;
  rowGap?: string;
  columnGap?: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.columns || 'auto 1fr'};
  grid-column-gap: ${(props) => props.columnGap};
  grid-row-gap: ${(props) => props.rowGap};
  grid-column-gap: ${(props) => props.columnGap};
`;

export default ButtonContainer;
