import React, { createContext, useState } from 'react';

import theme from '$/assets/theme';

interface IThemeBackgroundContext {
  background: string;
  setBackground: React.Dispatch<React.SetStateAction<string>>;
}

export const ThemeBackgroundContext = createContext<IThemeBackgroundContext>({
  background: theme.colors.white,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBackground: () => {},
});

/**
 * Provider that is used with `Layout` to change background dynamically depending on a current step like success
 */
const ThemeBackgroundProvider: React.FC = (props) => {
  const [background, setBackground] = useState(theme.colors.white);

  return (
    <ThemeBackgroundContext.Provider
      value={{
        background,
        setBackground,
      }}
    >
      {props.children}
    </ThemeBackgroundContext.Provider>
  );
};

export default ThemeBackgroundProvider;
