import type { IListProps } from '../components/elements/List';

import {
  faFileExport,
  faHandHoldingUsd,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useContext, useState } from 'react';

import MobileMenuDrawer from '../components/elements/Layout/MobileMenuDrawer';

import { CurrentUserContext } from './CurrentUserProvider';

interface DrawerItem extends IListProps {
  href: string;
}

type TContext = {
  mobileMenuOpen: boolean;
  toggleMobileMenuOpen: () => void;
  drawerItems: DrawerItem[];
};

export const MobileNavigationContext = createContext<TContext>({
  mobileMenuOpen: false,
  toggleMobileMenuOpen: () => null,
  drawerItems: [],
});

const textProps = {
  style: {
    fontSize: '15px',
  },
};

const MobileNavigationProvider = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { authenticated, signOut } = useContext(CurrentUserContext);

  const router = useRouter();

  const handleAuthButtonClick = useCallback(async () => {
    if (authenticated) {
      await signOut();
    } else {
      await router.push('/signin');
    }
  }, [router, authenticated, signOut]);

  const [drawerItems] = useState([
    {
      text: 'Unser Angebot',
      icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
      href: '/details/financing',
      textProps,
    },
    {
      text: 'Deine Anfrage',
      icon: <FontAwesomeIcon icon={faFileExport} />,
      href: '/details/financing',
      textProps,
    },
    {
      text: 'Deine Daten',
      icon: <FontAwesomeIcon icon={faUserCircle} />,
      href: '/',
      textProps,
    },
    {
      text: 'Abmelden',
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      href: '/',
      textProps: {
        ...textProps,
        onClick: handleAuthButtonClick,
      },
    },
    { text: 'Hilfe & Kontakt', href: '/', textProps },
  ]);

  const toggleMobileMenuOpen = useCallback(() => {
    setMobileMenuOpen((prevState) => !prevState);
  }, []);

  return (
    <MobileNavigationContext.Provider
      value={{
        mobileMenuOpen,
        toggleMobileMenuOpen,
        drawerItems,
      }}
    >
      {props.children}
      <MobileMenuDrawer />
    </MobileNavigationContext.Provider>
  );
};

export default MobileNavigationProvider;
