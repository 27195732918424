import styled from 'styled-components';

export const ActiveNotificationContainer = styled('div')`
  position: relative;
`;

/**
 * @example
 * <ActiveNotificationContainer>
 *   <Button ... />
 *   <ActiveNotification />
 * </ActiveNotificationContainer>
 */
const ActiveNotification = styled('div')`
  position: absolute;
  height: 8px;
  width: 8px;
  background: ${(props) => props.theme.colors.functionalRed};
  top: 15px;
  left: 15px;
  border-radius: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 30px;
  }
`;

export default ActiveNotification;
