import { faSignOutAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../../../../providers/CurrentUserProvider';
import Button from '../../Button';
import { menuDrawerItemProps } from '../MenuDrawerItems';

const Abmelden = () => {
  const { signOut, authenticated } = useContext(CurrentUserContext);
  const router = useRouter();

  const handleAuthButtonClick = useCallback(async () => {
    if (authenticated) {
      await signOut();
    } else {
      await router.push('/signin');
    }
  }, [router, authenticated, signOut]);

  return (
    <Button
      title={'Abmelden'}
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
      buttonProps={{
        ...menuDrawerItemProps.buttonProps,
        onClick: handleAuthButtonClick,
      }}
    />
  );
};

export default React.memo(Abmelden);
