import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../Button';
import { menuDrawerItemProps } from '../MenuDrawerItems';

const DeineAnfrage = () => (
  <Button
    title="Deine Anfrage"
    variant="menu"
    leftIcon={<FontAwesomeIcon icon={faFileExport} />}
    {...menuDrawerItemProps}
    link={{ href: '/details/financing' }}
  />
);
export default React.memo(DeineAnfrage);
