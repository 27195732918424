import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import DesktopContainer from '../../../DesktopContainer';
import MobileContainer from '../../../MobileContainer';
import { PTypography } from '../../../Typography';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const DeinKonto = ({ open }: { open?: boolean }) => (
  <>
    <DesktopContainer>
      <Button
        title="Dein Konto"
        variant="menu"
        leftIcon={<FontAwesomeIcon icon={faUser} />}
        buttonProps={{
          ...menuDrawerItemProps.buttonProps,
          ...(open && { selected: 'selected' }),
        }}
      />
    </DesktopContainer>
    <MobileContainer>
      <PTypography
        style={{
          ...menuDrawerItemProps.buttonProps.style,
          paddingBottom: '0px',
        }}
      >
        DEIN KONTO
      </PTypography>
    </MobileContainer>
  </>
);

export default React.memo(DeinKonto);
