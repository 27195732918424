import { faHome } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import Button from '../../Button';
import { menuDrawerItemProps } from '../MenuDrawerItems';

const Start = () => {
  return (
    <Button
      title={'Start'}
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faHome} />}
      {...menuDrawerItemProps}
      link={{ href: '/' }}
    />
  );
};

export default React.memo(Start);
