import React, { createContext, useState } from 'react';

type TContext = {
  progressBarValue: null | number;
  setProgressBarValue: (prop: null | number) => void;
  headerButton?:
    | {
        text: string;
        props: object;
      }
    | undefined;
  setHeaderButton: (prop: any | undefined) => void;
};

export const HeaderContext = createContext<TContext>({
  progressBarValue: null,
  setProgressBarValue: (prop: null | number) => null,
  headerButton: undefined,
  setHeaderButton: (prop: null | number) => null,
});

export interface IHeaderProviderProps {
  children: React.ReactNode;
  progressBarValue?: number;
  headerButton?: {
    text: string;
    props: object;
  };
}

const HeaderProvider = ({
  children,
  progressBarValue: barValue,
}: IHeaderProviderProps) => {
  const [progressBarValue, setProgressBarValue] = useState<null | number>(
    barValue ?? null
  );
  const [headerButton, setHeaderButton] = useState<any | undefined>(undefined);

  return (
    <HeaderContext.Provider
      value={{
        progressBarValue,
        setProgressBarValue,
        headerButton,
        setHeaderButton,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
