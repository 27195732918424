import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const Veranstaltungen = () => {
  const { enableNewEventsOverview } = useFlags();
  return (
    <Button
      title="Veranstaltungen"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faCalendar} />}
      {...menuDrawerItemProps}
      link={{
        href: enableNewEventsOverview
          ? '/customer-portal/events'
          : 'https://www.deutsche-bildung.de/mein-kundenportal/meine-termine/',
      }}
    />
  );
};

export default React.memo(Veranstaltungen);
