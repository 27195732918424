import styled from 'styled-components';

const StyledNav = styled.nav`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  width: 100%;
`;

export default StyledNav;
