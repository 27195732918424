import styled from 'styled-components';

import Container from './Container';
import DrawerHeader from './DrawerHeader';
import DrawerItem from './DrawerItem';
import DrawerList from './DrawerList';
import Wrapper from './Wrapper';

const MobileDrawer: any = styled.div``;

MobileDrawer.DrawerList = DrawerList;
MobileDrawer.DrawerItem = DrawerItem;
MobileDrawer.DrawerHeader = DrawerHeader;
MobileDrawer.Container = Container;
MobileDrawer.Wrapper = Wrapper;

export default MobileDrawer;
