import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Button from '../../../Button';
import { menuDrawerItemProps } from '../../MenuDrawerItems';

const UpdateMitteilen = () => {
  return (
    <Button
      title="Update mitteilen"
      variant="menu"
      leftIcon={<FontAwesomeIcon icon={faFileAlt} />}
      {...menuDrawerItemProps}
      link={{
        href: '/voluntary-update',
      }}
    />
  );
};

export default React.memo(UpdateMitteilen);
