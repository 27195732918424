import type { ITypography } from '../Typography';
import type { FC } from 'react';

import { faCheck, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { CurrentUserContext } from '../../../providers/CurrentUserProvider';
import { StepsContext } from '../../../providers/StepsProvider';
import Underline from '../../blocks/HeadContentSection/Underline';
import StepsBlock from '../../blocks/StepsBlock';
import Button from '../Button';

import theme from '$/assets/theme';
import useCurrentUser from '$/hooks/useCurrentUser';
import checkUserHasAllDocuments from '$/utils/checkUserHasAllDocuments';

interface ISteps {
  headerComponent?: React.ComponentType<ITypography>;
}

const CompleteIcon = () => (
  <div
    style={{
      borderRadius: '100%',
      border: `1px solid ${theme.colors.brandGreen}`,
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.colors.brandGreen,
    }}
  >
    <FontAwesomeIcon icon={faCheck} color={theme.colors.white} />
  </div>
);

const changeSvgColor = css`
  svg {
    color: ${theme.colors.white};
  }
`;

const RegularIconWrapper = styled('div')<{ svgColor?: boolean }>`
  border-radius: 100%;
  border: 1px solid ${theme.colors.grey3};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.grey5};
  font-weight: bold;

  ${(props) => props.svgColor && changeSvgColor};
`;

const RegularIcon = ({
  active,
  children,
  style = {},
}: {
  active: boolean;
  children: string | React.ReactNode;
  style?: object;
}) => (
  <RegularIconWrapper
    style={{ ...style }}
    svgColor={typeof children !== 'string' && active}
  >
    {children}
  </RegularIconWrapper>
);

const ChevronIcon = () => (
  <div
    style={{
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <FontAwesomeIcon icon={faChevronRight} color={theme.colors.grey8} />
  </div>
);

const Steps: FC<ISteps> = (props) => {
  const { progressItems, setProgressItems } = useContext(StepsContext);
  const { userData } = useCurrentUser();

  const { pathname, query } = useRouter();

  useEffect(() => {
    const copyItems = [...progressItems];
    if (userData?.financingPackage?.id) {
      copyItems[2] = { ...copyItems[2], completed: true, inactive: true };
    }
    if (userData?.nationalityId) {
      copyItems[3] = { ...copyItems[3], completed: true, inactive: true };
    }
    const hasAllDocuments = checkUserHasAllDocuments(userData);
    if (hasAllDocuments) {
      copyItems[4] = { ...copyItems[4], completed: true, inactive: true };
    }
    copyItems[5] = { ...copyItems[5], inactive: false };
    switch (pathname) {
      case '/details/financing':
        copyItems[2] = { ...copyItems[2], inactive: false };
        copyItems[5] = { ...copyItems[5], inactive: true };
        break;
      case '/details/personal-infos':
        copyItems[2] = { ...copyItems[2], inactive: true };
        copyItems[3] = { ...copyItems[3], inactive: false };
        copyItems[5] = { ...copyItems[5], inactive: true };
        break;
      case '/details/document-upload':
        copyItems[2] = { ...copyItems[2], inactive: true };
        copyItems[3] = { ...copyItems[3], completed: true, inactive: true };
        copyItems[4] = { ...copyItems[4], inactive: false };
        copyItems[5] = { ...copyItems[5], inactive: true };
        break;
      case '/details/final-step':
      case '/details/success':
        copyItems[2] = { ...copyItems[2], inactive: true };
        copyItems[3] = { ...copyItems[3], completed: true, inactive: true };
        copyItems[4] = { ...copyItems[4], inactive: true };
        copyItems[5] = { ...copyItems[5], inactive: false };
        break;
    }
    setProgressItems(copyItems);
  }, [pathname, query, userData]);

  const numberOfRemainingSteps = useMemo<number>(() => {
    const _numberOfRemainingSteps =
      progressItems.length - progressItems.filter((p) => p.completed).length;
    // Making sure to display at least 1
    // when we hit 0 then we are not displaying Steps anymore
    return _numberOfRemainingSteps > 0 ? _numberOfRemainingSteps : 1;
  }, [progressItems]);

  return (
    <StepsBlock>
      {props.headerComponent && (
        <props.headerComponent as="h1" bold color="grey7">
          Noch <Underline>{numberOfRemainingSteps} Schritte</Underline>!
        </props.headerComponent>
      )}

      <StepsBlock.ProgressList>
        {progressItems.map((progressItem) => (
          <StepsBlock.ProgressItem key={progressItem.id}>
            {progressItem.completed && (
              <Button
                title={progressItem.text}
                variant="trueTransparent"
                buttonProps={{
                  style: {
                    padding: '16px 0',
                    cursor: 'default',
                  },
                }}
                textProps={{
                  style: {
                    width: '100%',
                    textAlign: 'left',
                    color: theme.colors.grey7,
                  },
                }}
                leftIcon={<CompleteIcon />}
                // link={{ href: progressItem.link || '' }}
              />
            )}
            {!progressItem.completed && (
              <Button
                title={progressItem.text}
                variant="trueTransparent"
                buttonProps={{
                  style: {
                    padding: '16px 0',
                    cursor: 'default',
                  },
                }}
                textProps={{
                  style: {
                    width: '100%',
                    textAlign: 'left',
                    color: theme.colors.grey7,
                    fontWeight: !progressItem.inactive && 'bold',
                  },
                }}
                // link={{ href: progressItem.link || '' }}
                leftIcon={
                  <RegularIcon
                    active={!progressItem.inactive}
                    style={
                      !progressItem.inactive && {
                        borderColor: theme.colors.brandBlue,
                        background: theme.colors.brandBlue,
                        color: theme.colors.white,
                      }
                    }
                  >
                    {progressItem.number}
                  </RegularIcon>
                }
                // rightIcon={!progressItem.inactive && <ChevronIcon />}
              />
            )}
          </StepsBlock.ProgressItem>
        ))}
      </StepsBlock.ProgressList>
    </StepsBlock>
  );
};

export default Steps;
